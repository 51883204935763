import { InMemoryCache } from '@apollo/client';
import { AuthHelpers } from 'helpers'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return SetIsLoggedInVar();
        },
        isShowSidebar() {
          return setIsShowSidebar();
        },
        role() {
          return SetRole();
        },
        listRoles() {
          return SetListRoles();
        },
        campagneSelected() {
          return SetCampagneCache();
        },
        statusCampagne() {
          return SetStatusCampagneCache();
        },
        clientSelected() {
          return SetClientCache();
        },
        userLogin() {
          return SetUserLogin();
        },
        sidebarShow() {
          return SetSidebarShow();
        },
        templateFields() {
          return SetTemplateFields()
        },
        disableUpdateTemplate() {
          return SetDisableUpdateTemplate()
        },
        prodonFields() {
          return SetProDonFields()
        },
        disableProdonFields() {
          return SetDisableProdonTemplate();
        },
        templateEmail() {
          return SetTemplateEmail()
        },
        logTypeSelected() {
          return SetLogTypeCache();
        },
        isNotification() {
          return SetNotificationStatus();
        },
        storyBoard() {
          return SetStoryBoard();
        }
      }
    }
  }
});

// isLogin
export const SetIsLoggedInVar = cache.makeVar<boolean>(!!AuthHelpers.checkAuthen());

// Set Rule List
export const SetRole = cache.makeVar<number>(AuthHelpers.getRole() || 3);

// Set Rule Current
export const SetListRoles = cache.makeVar<string[]>([]);

// Set isShowSidebar
export const setIsShowSidebar = cache.makeVar(true);

// Set Campagne Selected
export const SetCampagneCache = cache.makeVar("");
export const SetStatusCampagneCache = cache.makeVar("");
export const SetClientCache = cache.makeVar("");

// Set user login
export const SetUserLogin = cache.makeVar<any>(AuthHelpers.getUserLogin());

// Set sidebarShow
export const SetSidebarShow = cache.makeVar<boolean>(true);

// Set fields of template
export const SetTemplateFields = cache.makeVar<any>([]);

// Set disable update fields in Template
export const SetDisableUpdateTemplate = cache.makeVar<boolean>(false);

// Set prodonTemplate in campagne
export const SetProDonFields = cache.makeVar<any>([]);

// Set disable update prodonTemplate in campagne
export const SetDisableProdonTemplate = cache.makeVar<boolean>(false);

// Set template Email in Campagne
export const SetTemplateEmail = cache.makeVar<any>([]);

// Set log type selected in Log management
export const SetLogTypeCache = cache.makeVar<string>("donor");

// Set Notification
export const SetNotificationStatus = cache.makeVar<boolean>(false);

// SET Échéancier
export const SetStoryBoard = cache.makeVar<any>([]);